import React from "react";

import { SVG } from "./styles";
import { getDefaultHeight } from "./utils";

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const ZoomInIcon: React.FC<Props> = ({
  color = "currentColor",
  ...svgProps
}) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      height={getDefaultHeight(svgProps, 32)}
      viewBox="0 0 32 32"
      {...svgProps}
      fill={color}
    >
      <title>Zoom ind</title>
      <path d="M13.301 26.535c3.139 0 6.030-1.107 8.31-2.936l7.904 7.972c0.294 0.294 0.677 0.429 1.039 0.429 0.384 0 0.745-0.136 1.039-0.429 0.587-0.565 0.587-1.513 0-2.078l-7.927-7.972c1.807-2.258 2.891-5.126 2.891-8.243 0-7.317-5.962-13.279-13.279-13.279s-13.279 5.962-13.279 13.279c0.045 7.294 5.984 13.256 13.301 13.256zM13.301 2.936c5.691 0 10.32 4.63 10.32 10.32s-4.63 10.32-10.32 10.32c-5.691 0-10.32-4.63-10.32-10.32s4.63-10.32 10.32-10.32z"></path>
      <path d="M15.153 7.001v4.539h4.539v3.004h-4.539v4.539h-3.004v-4.539h-4.539v-3.004h4.539v-4.539h3.004z"></path>
    </SVG>
  );
};
