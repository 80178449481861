import React from "react";
import { hot } from "react-hot-loader/root";

import { Args } from "..";

import { ImageZoom } from "./ImageZoom";

function RootUnwrapped(args: Args): JSX.Element {
  return <ImageZoom {...args} />;
}

export const Root = hot(RootUnwrapped);
