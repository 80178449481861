import { styled } from "linaria/react";

export const SVG = styled.svg`
  /**
   * svg implements it's own pointer event system, which rarely works the way
   * you'd expect usually - simply disable pointer events all together to avoid
   * confusion.
   */
  pointer-events: none;
  display: block;
`;
