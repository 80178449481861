import { cx } from "linaria";
import React, { Component } from "react";

import { Args } from "..";
import { isIOS, isAndroid, isWP } from "../utils/deviceDetection";
import { inOutQuart } from "../utils/easings";
import { throttleToAnimationFrame } from "../utils/throttleToAnimationFrame";

import { ArrowIcon } from "./icons/Arrow";
import { CrossIcon } from "./icons/Cross";
import { ZoomInIcon } from "./icons/ZoomIn";
import { ZoomOutIcon } from "./icons/ZoomOut";
import * as containerCss from "./styles/container";
import * as descriptionCss from "./styles/description";
import * as toolsCss from "./styles/tools";

const TRACK_WIDTH_PX = 96;

type Resolutions = "xhd" | "hd" | "sd" | "unknown";

type State = {
  resolvedSrc: string;
  imgResolution: Resolutions;
  screenResolution: Resolutions;

  loading: boolean;
  initialized: boolean;

  currZoom: number;
  minZoom: number;
  maxZoom: number;

  dragging: boolean;

  descriptionVisible: boolean;
  zoomSliderVisible: boolean;

  trackStart?: number;
  trackEnd?: number;
};

export class ImageZoom extends Component<Args, State> {
  public state: State = {
    loading: true,
    initialized: false,
    currZoom: 1,
    minZoom: 1,
    maxZoom: 4,
    dragging: false,
    descriptionVisible: true,
    zoomSliderVisible: true,
    ...this.determineResolutions(),
  };

  private scrollerRef = React.createRef<HTMLDivElement>();
  private imageRef = React.createRef<HTMLImageElement>();
  private spacerRef = React.createRef<HTMLDivElement>();
  private throbberRef = React.createRef<HTMLDivElement>();

  private zoomToolTrack = React.createRef<HTMLDivElement>();
  private zoomToolThumb = React.createRef<HTMLDivElement>();

  private currentDragImageAnchor?: { x: number; y: number };
  private currentPinchContainerAnchor?: { x: number; y: number };
  private currentPinchImageAnchor?: { x: number; y: number };
  private prevPinchDiff?: number;

  private dragScrollCursorStart?: { x: number; y: number };
  private dragScrollStartOffset?: { x: number; y: number };

  public componentDidMount(): void {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);

    if (!this.scrollerRef.current) {
      console.warn(
        "Unable to initialize gImageZoom. scrollerNode not available"
      );
      return;
    }

    // The imageZoom component is ready once the image has been fully loaded
    if (this.imageRef.current?.complete) {
      this.setState({ loading: false });
    } else {
      this.imageRef.current?.addEventListener("load", () => {
        this.setState({ loading: false });
      });
    }

    // Add event listener when user pinches to zoom
    this.scrollerRef.current?.addEventListener(
      "touchstart",
      this.onTouchStart,
      { passive: false }
    );
  }

  public componentDidUpdate(): void {
    // The first time the image has finished loading (but IScroll has not been
    // properly initialized yet), initialize the zoom plugin!
    if (!this.state.initialized && !this.state.loading) {
      this.initializeZoomLevels();
      this.setupWhitespace();
      this.toggleZoomSlider();
      this.setState({ initialized: true });

      setTimeout(() => {
        const throbber = this.throbberRef.current;

        if (throbber) {
          throbber.style.display = "none";
        }
      }, 500);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
    this.scrollerRef.current?.removeEventListener(
      "touchstart",
      this.onTouchStart
    );
  }

  public render(): JSX.Element {
    const currRangePx = Math.ceil(
      ((this.state.currZoom - this.state.minZoom) /
        Math.max(this.state.maxZoom - this.state.minZoom, 0)) *
        TRACK_WIDTH_PX
    );

    const currRangePercent = Math.ceil(
      ((this.state.currZoom - this.state.minZoom) /
        Math.max(this.state.maxZoom - this.state.minZoom, 0)) *
        100
    );

    return (
      <div
        className={cx(
          containerCss.containerCls,
          isIOS || isAndroid || isWP ? "touch" : "mouse"
        )}
      >
        <div
          className={cx(
            containerCss.imageZoomCls,
            this.state.loading && "loading"
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={containerCss.scrollerCls}
            ref={this.scrollerRef}
            onMouseDown={this.handleScrollerMouseDown}
          >
            <img
              className={containerCss.imageCls}
              ref={this.imageRef}
              src={this.state.resolvedSrc}
              alt=""
            />
            <div className={containerCss.spacerCls} ref={this.spacerRef} />
          </div>
          {this.props.text && (
            <div
              className={cx(
                descriptionCss.panelCls,
                this.state.descriptionVisible && "visible",
                !this.state.zoomSliderVisible && "withoutZoomSlider",
                this.state.maxZoom === 1 && "noRightPadding"
              )}
            >
              <div className={descriptionCss.descriptionCls}>
                <button
                  className={cx(
                    descriptionCss.closeCls,
                    this.state.descriptionVisible ? "cross" : "arrow"
                  )}
                  onClick={this.toggleDescription}
                >
                  <ArrowIcon
                    className={cx(descriptionCss.closeIconCls, "arrow")}
                    width={14}
                  />
                  <CrossIcon
                    className={cx(descriptionCss.closeIconCls, "cross")}
                    width={14}
                  />
                </button>
                <span
                  dangerouslySetInnerHTML={{ __html: this.props.text }}
                ></span>
              </div>
            </div>
          )}
          <div
            className={cx(
              toolsCss.toolsCls,
              this.state.maxZoom !== 1 && "visible"
            )}
          >
            <button
              id="ratio-tool"
              className={cx(
                toolsCss.buttonCls,
                toolsCss.ratioButtonCls,
                "small"
              )}
              onClick={this.ratio}
            >
              <span className={toolsCss.ratioButtonTextCls}>1:1</span>
            </button>
            <button
              id="shrink-tool"
              className={cx(toolsCss.buttonCls, toolsCss.shrinkButtonCls)}
              onClick={this.onShrink}
            >
              <ZoomOutIcon height={23} className={toolsCss.buttonIconCls} />
            </button>
            <div
              className={cx(
                toolsCss.groupCls,
                !this.state.zoomSliderVisible && "hidden"
              )}
            >
              <span
                id="zoom-indicator"
                className={toolsCss.thumbIndicatorCls}
                style={{
                  left: currRangePx,
                }}
              >
                {Math.round((this.state.currZoom / this.state.maxZoom) * 100)}%
              </span>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                id="zoom-tool"
                className={toolsCss.trackCls}
                ref={this.zoomToolTrack}
                onMouseDown={this.onZoomStart}
                onTouchStart={this.onZoomStart}
                style={{
                  background: `linear-gradient(to right, #dc4320 0%, #dc4320 ${currRangePercent}%, rgba(0,0,0,0) ${currRangePercent}%`,
                }}
              >
                <div
                  className={toolsCss.thumbCls}
                  ref={this.zoomToolThumb}
                  style={{
                    left: currRangePx,
                  }}
                >
                  <span className={toolsCss.thumbIconCls} />
                </div>
              </div>
            </div>
            <button
              id="enlarge-tool"
              className={cx(toolsCss.buttonCls, toolsCss.enlargeButtonCls)}
              onClick={this.onEnlarge}
            >
              <ZoomInIcon height={23} className={toolsCss.buttonIconCls} />
            </button>
          </div>
        </div>
        <div
          ref={this.throbberRef}
          className={cx(
            containerCss.throbberCls,
            this.state.loading && "loading"
          )}
        ></div>
      </div>
    );
  }

  /**
   * Function to toggle the description panel in and out of view
   */
  private toggleDescription = (): void => {
    this.setState({ descriptionVisible: !this.state.descriptionVisible });
  };

  private initializeZoomLevels = (): void => {
    const imageNode = this.imageRef.current;

    let ratio: number;

    // Determine the ratio between image dimensions and its container, since it
    // is needed to determine that maximum level we should be able to zoom.
    const ratioNode =
      this.props.node.clientWidth / this.props.node.clientHeight;
    const ratioImg =
      (imageNode?.naturalWidth ?? 0) / (imageNode?.naturalHeight ?? 1);

    if (ratioNode < ratioImg) {
      //Scale image to fit horizontally
      ratio = (imageNode?.naturalWidth ?? 0) / (imageNode?.clientWidth ?? 1);
    } else {
      //Scale image to fit vertically
      ratio = (imageNode?.naturalHeight ?? 0) / (imageNode?.clientHeight ?? 1);
    }

    // Determine that maximum soom level based on current resolutions
    let maxZoom = ratio;

    switch (this.state.screenResolution) {
      case "hd":
        switch (this.state.imgResolution) {
          case "sd":
            maxZoom = ratio * 0.75;
            break;

          case "hd":
            maxZoom = ratio * 0.5;
            break;

          default:
            maxZoom = ratio * 0.75;
        }
        break;

      case "xhd":
        switch (this.state.imgResolution) {
          case "sd":
            maxZoom = ratio * 0.75;
            break;

          case "hd":
            maxZoom = ratio * 0.5;
            break;

          case "xhd":
            maxZoom = ratio * 0.33;
            break;

          default:
            maxZoom = ratio * 0.75;
        }
        break;

      default:
      // No other screenTypes will exist
    }

    //if image is smaller than node, force image to scale to node
    if (maxZoom <= 1.1) {
      maxZoom = 1;
    }

    //onResize make sure that currZoom is not greater than maxZoom
    if (this.state.currZoom > maxZoom) {
      this.zoom(maxZoom, this.getCurrentImageAnchor());
    }

    //Get proper position of zoomThumb
    const trackStart =
      this.zoomToolTrack.current?.getBoundingClientRect().left ?? 0;
    this.setState({
      maxZoom,
      trackStart,
      trackEnd: trackStart + TRACK_WIDTH_PX,
    });
  };

  //TOOLS
  public onZoomStart = (
    evt: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ): void => {
    evt.preventDefault();
    this.currentDragImageAnchor = this.getCurrentImageAnchor();

    this.setState({ dragging: true });
    this.onZoom(evt);

    if ("clientX" in evt) {
      document.body.addEventListener("mousemove", this.onZoom, {
        passive: false,
      });
      document.body.addEventListener("mouseup", this.onZoomEnd);
      document.body.addEventListener("mouseleave", this.onZoomEnd);
    } else {
      document.body.addEventListener("touchmove", this.onZoom, {
        passive: false,
      });
      document.body.addEventListener("touchend", this.onZoomEnd);
    }
  };

  public onZoomEnd = (): void => {
    if (this.state.dragging) {
      this.currentDragImageAnchor = undefined;
      this.setState({ dragging: false });
    }

    document.body.removeEventListener("mouseup", this.onZoomEnd);
    document.body.removeEventListener("touchend", this.onZoomEnd);
    document.body.removeEventListener("mouseleave", this.onZoomEnd);
    document.body.removeEventListener("mousemove", this.onZoom);
    document.body.removeEventListener("touchmove", this.onZoom);
  };

  public onZoom = (
    evt:
      | MouseEvent
      | TouchEvent
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent
  ): void => {
    evt.preventDefault();

    if (this.state.dragging) {
      let currPos = 0;

      if ("clientX" in evt) {
        currPos = evt.clientX;
      } else {
        currPos = evt.changedTouches[0].pageX;
      }

      let position = Math.round(currPos - (this.state.trackStart ?? 0));
      if (position < 0) {
        position = 0;
      } else if (position > TRACK_WIDTH_PX) {
        position = TRACK_WIDTH_PX;
      }

      const currZoom =
        (position / TRACK_WIDTH_PX) * (this.state.maxZoom - 1) + 1;

      if (this.currentDragImageAnchor) {
        this.zoom(currZoom, this.currentDragImageAnchor);
      }
    }
  };

  private onShrink = (): void => {
    // Zoom out 20%
    let newZoom =
      this.state.currZoom - (this.state.maxZoom - this.state.minZoom) / 5;

    if (newZoom < this.state.minZoom) {
      newZoom = this.state.minZoom;
    }

    this.animateScale(newZoom, 300);
  };

  private onEnlarge = (): void => {
    //Zoom in 20%
    let newZoom =
      this.state.currZoom + (this.state.maxZoom - this.state.minZoom) / 5;

    if (newZoom > this.state.maxZoom) {
      newZoom = this.state.maxZoom;
    }

    this.animateScale(newZoom, 300);
  };

  private ratio = (): void => {
    this.animateScale(this.state.minZoom, 500);
  };

  /**
   * Helper that animates from one zoom-level to another, applying an easing
   * in the process.
   */
  private animateScale = (newZoom: number, duration: number): void => {
    const startTime = performance.now();
    const startZoom = this.state.currZoom;
    const imgAnchor = this.getCurrentImageAnchor();

    const animateStep = () => {
      const now = performance.now() - startTime;
      if (now < duration) {
        // Calculate zoomLevel
        const zoomLevel = (newZoom - startZoom) * inOutQuart(now / duration);
        const currZoom = zoomLevel + startZoom;

        // Apply zoomlevel
        this.zoom(currZoom, imgAnchor);

        requestAnimationFrame(animateStep);
      } else {
        // Finishing animation
        this.zoom(newZoom, imgAnchor);
      }
    };

    animateStep();
  };

  private zoom = (
    scale: number,
    imgAnchor: { x: number; y: number },
    containerAnchor: { x: number; y: number } = { x: 0.5, y: 0.5 }
  ): void => {
    if (scale > this.state.maxZoom || scale < this.state.minZoom) {
      return;
    }

    const img = this.imageRef.current;
    const scroller = this.scrollerRef.current;
    const spacer = this.spacerRef.current;

    if (!img || !scroller || !spacer) {
      return;
    }

    const nextImageWidth = img.clientWidth * scale;
    const nextImageHeight = img.clientHeight * scale;

    const whitespace = {
      left: Math.max(0, (scroller.clientWidth - nextImageWidth) / 2),
      top: Math.max(0, (scroller.clientHeight - nextImageHeight) / 2),
    };

    const nextScrollX =
      nextImageWidth * imgAnchor.x - scroller.clientWidth * containerAnchor.x;
    const nextScrollY =
      nextImageHeight * imgAnchor.y - scroller.clientHeight * containerAnchor.y;

    // Apply new zoom level!
    img.style.margin = `${whitespace.top}px ${whitespace.left}px`;
    img.style.transform = `scale(${scale})`;

    // And setup the spacer to be as wide and tall as the image in pixels. This
    // is necessary since browsers are not good at picking up the proper
    // scrollWidth for a parent with scaled children.
    // The consequence of this is that we might not be able to scroll far enough
    // in the scrollTo step below since the browser believes that the scroll position
    // may be out of bounds, even though it is not.
    spacer.style.width = `${nextImageWidth}px`;
    spacer.style.height = `${nextImageHeight}px`;
    spacer.style.marginTop = `-${img.clientHeight}px`;
    spacer.style.marginLeft = `-${img.clientWidth}px`;

    scroller.scrollTo({
      left: whitespace.left > 0 ? 0 : nextScrollX,
      top: whitespace.top > 0 ? 0 : nextScrollY,
      behavior: "auto",
    });

    this.setState({ currZoom: scale });
  };

  private setupWhitespace = (scale = this.state.currZoom) => {
    const whitespaceNode = this.imageRef.current;
    const scroller = this.scrollerRef.current;

    if (!whitespaceNode || !scroller) {
      return;
    }

    const scaled = {
      height: whitespaceNode.clientHeight * scale,
      width: whitespaceNode.clientWidth * scale,
    };

    const whitespace = {
      top: Math.max(0, (scroller.clientHeight - scaled.height) / 2),
      left: Math.max(0, (scroller.clientWidth - scaled.width) / 2),
    };

    whitespaceNode.style.margin = `${whitespace.top}px ${whitespace.left}px`;
  };

  private toggleZoomSlider = () => {
    if (!this.scrollerRef.current) {
      return;
    }

    const breakpoint = this.props.text ? 500 : 300;

    // toggle the zoom slider on/off dependening on the size of the parent layer
    // (ie. display it only if there is actually enough space to do so)
    this.setState({
      zoomSliderVisible: this.scrollerRef.current.clientWidth > breakpoint,
    });
  };

  private onResize = throttleToAnimationFrame(() => {
    this.initializeZoomLevels();
    this.setupWhitespace();
    this.toggleZoomSlider();
  });

  /**
   * Extracts resolutions of screen and image and also includes the proper src
   * that matches these resolutions.
   */
  private determineResolutions(): {
    screenResolution: Resolutions;
    imgResolution: Resolutions;
    resolvedSrc: string;
  } {
    let screenResolution: Resolutions = "unknown";
    let imgResolution: Resolutions = "unknown";
    let resolvedSrc = "";

    if (window.devicePixelRatio < 1.5) {
      screenResolution = "sd";
    } else if (window.devicePixelRatio < 2.5) {
      screenResolution = "hd";
    } else {
      screenResolution = "xhd";
    }

    if (typeof this.props.src == "string") {
      imgResolution = "unknown";
      resolvedSrc = this.props.src;
    } else {
      switch (screenResolution) {
        case "xhd":
          if (this.props.src.xhd) {
            imgResolution = "xhd";
            resolvedSrc = this.props.src.xhd;
          } else if (this.props.src.hd) {
            imgResolution = "hd";
            resolvedSrc = this.props.src.hd;
          } else if (this.props.src.sd) {
            imgResolution = "sd";
            resolvedSrc = this.props.src.sd;
          }
          break;

        case "hd":
          if (this.props.src.hd) {
            imgResolution = "hd";
            resolvedSrc = this.props.src.hd;
          } else if (this.props.src.sd) {
            imgResolution = "sd";
            resolvedSrc = this.props.src.sd;
          }
          break;

        case "sd":
          imgResolution = "sd";
          resolvedSrc = this.props.src.sd ?? "unknown";
          break;
      }
    }

    return { screenResolution, imgResolution, resolvedSrc };
  }

  private onTouchStart = (evt: TouchEvent) => {
    const scroller = this.scrollerRef.current;
    const scrollerBBox = scroller?.getBoundingClientRect();

    const img = this.imageRef.current;
    const imageBBox = img?.getBoundingClientRect();

    if (!scroller || !scrollerBBox || !imageBBox) {
      return;
    }

    if (evt.touches.length === 2) {
      evt.preventDefault();

      this.prevPinchDiff = this.pinchDist(evt.touches);
    }

    // determine center coordinate of the touch
    const touchCenter = Array.from(evt.touches).reduce(
      (acc, touch, index, touches) => ({
        x: acc.x + touch.pageX / touches.length,
        y: acc.y + touch.pageY / touches.length,
      }),
      {
        x: 0,
        y: 0,
      }
    );

    this.currentPinchContainerAnchor = {
      x: (touchCenter.x - scrollerBBox.left) / scrollerBBox.width,
      y: (touchCenter.y - scrollerBBox.top) / scrollerBBox.height,
    };

    this.currentPinchImageAnchor = {
      x:
        (scroller.scrollLeft +
          scroller.clientWidth * this.currentPinchContainerAnchor.x) /
        Math.max(scroller.clientWidth, imageBBox.width),
      y:
        (scroller.scrollTop +
          scroller.clientHeight * this.currentPinchContainerAnchor.y) /
        Math.max(scroller.clientHeight, imageBBox.height),
    };

    window.addEventListener("touchmove", this.onPinch, { passive: false });
    window.addEventListener("touchcancel", this.onPinchEnd);
    window.addEventListener("touchend", this.onPinchEnd);
  };

  private onPinch = (evt: TouchEvent) => {
    if (evt.touches.length !== 2) {
      return;
    }

    evt.preventDefault();

    const curDiff = this.pinchDist(evt.touches);

    if (!this.prevPinchDiff) {
      this.prevPinchDiff = curDiff;
      return;
    }

    const scroller = this.scrollerRef.current;
    const img = this.imageRef.current;
    const imageBBox = img?.getBoundingClientRect();

    if (
      !img ||
      !scroller ||
      !imageBBox ||
      !this.currentPinchImageAnchor ||
      !this.currentPinchContainerAnchor
    ) {
      return;
    }

    // Determine the new scale based on the distance between the current and
    // previous touch event
    const currentScale = imageBBox.width / img.clientWidth;
    const newScale = currentScale * (curDiff / this.prevPinchDiff);

    this.prevPinchDiff = curDiff;

    this.zoom(
      newScale,
      this.currentPinchImageAnchor,
      this.currentPinchContainerAnchor
    );
  };

  private onPinchEnd = () => {
    this.prevPinchDiff = undefined;

    window.removeEventListener("touchmove", this.onPinch);
    window.removeEventListener("touchcancel", this.onPinchEnd);
    window.removeEventListener("pointerup", this.onPinchEnd);
  };

  private pinchDist = (touches: TouchList) =>
    Math.sqrt(
      Math.pow(touches[0].pageX - touches[1].pageX, 2) +
        Math.pow(touches[0].pageY - touches[1].pageY, 2)
    );

  private handleScrollerMouseDown = (evt: React.MouseEvent) => {
    if (isIOS || isAndroid || isWP) {
      // touch devices will natively support inertia scrolling using swipe
      // gestures
      return;
    }

    evt.preventDefault();
    evt.stopPropagation();

    if (!this.scrollerRef.current) {
      return;
    }

    this.dragScrollCursorStart = {
      x: evt.pageX,
      y: evt.pageY,
    };

    this.dragScrollStartOffset = {
      x: this.scrollerRef.current.scrollLeft,
      y: this.scrollerRef.current.scrollTop,
    };

    window.addEventListener("mousemove", this.handleScrollerMouseMove);
    window.addEventListener("mouseup", this.handleScrollerMouseUp);
  };

  private handleScrollerMouseMove = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (
      !this.scrollerRef.current ||
      !this.dragScrollCursorStart ||
      !this.dragScrollStartOffset
    ) {
      return;
    }

    this.scrollerRef.current.scrollTo({
      top:
        this.dragScrollStartOffset.y -
        (evt.pageY - this.dragScrollCursorStart.y),
      left:
        this.dragScrollStartOffset.x -
        (evt.pageX - this.dragScrollCursorStart.x),
      behavior: "auto",
    });

    document.body.classList.add(containerCss.scrollGrabbingCls);
  };

  private handleScrollerMouseUp = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();

    window.removeEventListener("mousemove", this.handleScrollerMouseMove);
    window.removeEventListener("mouseup", this.handleScrollerMouseUp);

    document.body.classList.remove(containerCss.scrollGrabbingCls);
  };

  private getCurrentImageAnchor = () => {
    const scroller = this.scrollerRef.current;
    const imageBBox = this.imageRef.current?.getBoundingClientRect();

    if (!scroller || !imageBBox) {
      return {
        x: 0.5,
        y: 0.5,
      };
    }

    return {
      x:
        (scroller.scrollLeft + scroller.clientWidth * 0.5) /
        Math.max(scroller.clientWidth, imageBBox.width),
      y:
        (scroller.scrollTop + scroller.clientHeight * 0.5) /
        Math.max(scroller.clientHeight, imageBBox.height),
    };
  };
}
