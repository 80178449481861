import React from "react";
import { render } from "react-dom";

import { Root } from "./components/Root";
import { wrapperCls } from "./components/styles/container";

/**
 * Specifies a set of image-sources target for different screen-resolutions
 */
export type SrcSet = {
  /** Path to standard resolution image */
  sd?: string;

  /** Path to hd / retina resolution image (2x) */
  hd?: string;

  /** Path to xhd resolution image (3x) */
  xhd?: string;
};

/** Specifies what image to load into the plugin */
export type Args = {
  /** The node to inject the Zoom-plugin into */
  node: Element;

  /** The image to display inside the zoom'er */
  src: string | SrcSet;

  /** Specifies the caption to display on top of the image */
  text?: string;
};

declare global {
  interface Window {
    gImageZoom(args: Args): void;
  }
}

window.gImageZoom = function gImageZoom(args: Args) {
  const wrapper = document.createElement("div");
  wrapper.className = wrapperCls;
  args.node.appendChild(wrapper);

  render(<Root {...args} />, wrapper);
};
