/**
 * In case an explicit height or width has been set for a given icon, then
 * default icon dimensions should NOT be used. Therefore this utility ensures
 * that the default height is set to undefined in case an explicit height or width
 * prop has been set.
 */
export function getDefaultHeight(
  props: React.SVGProps<SVGSVGElement>,
  fallback: number
): undefined | number {
  return props.height ?? props.width !== undefined ? undefined : fallback;
}
