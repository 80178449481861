import { css } from "linaria";

export const scrollGrabbingCls = css`
  cursor: grabbing !important;
`;

export const imageZoomCls = css`
  z-index: 1000;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  overflow: hidden;

  &.loading {
    opacity: 0;
  }
`;

export const containerCls = css`
  width: 100%;
  height: 100%;
`;

export const wrapperCls = css`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const scrollerCls = css`
  width: 100%;
  height: 100%;

  overflow: auto;
  overflow: overlay;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Add a thumb */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px #fff;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;

export const imageCls = css`
  display: block;
  position: relative;
  height: auto;
  max-height: 100%;
  max-width: 100%;

  transform-origin: 0 0;
`;

export const spacerCls = css`
  pointer-events: none;
`;

export const throbberCls = css`
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  background: transparent;
  border: 2px solid #ccc;
  border-top-color: #383838;
  border-radius: 50%;

  animation: spin 1000ms infinite linear;

  &.loading {
    opacity: 1;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
