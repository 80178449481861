import React from "react";

import { SVG } from "./styles";
import { getDefaultHeight } from "./utils";

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const CrossIcon: React.FC<Props> = ({
  color = "currentColor",
  ...svgProps
}) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      height={getDefaultHeight(svgProps, 32)}
      viewBox="0 0 32 32"
      {...svgProps}
      fill={color}
    >
      <path d="M0 27.5l4.5 4.5 11.563-11.5 11.563 11.5 4.5-4.5-11.563-11.5 11.563-11.5-4.5-4.5-11.563 11.5-11.563-11.5-4.5 4.5 11.563 11.5z"></path>
    </SVG>
  );
};
