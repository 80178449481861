import { css } from "linaria";

export const panelCls = css`
  /* Position panel */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-right: 290px;
  z-index: 10000;

  /* Ensure tools is always wrapped in background */
  min-height: 73px;

  background-color: rgba(255, 255, 255, 0.5);

  &::before {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: -100px;
    z-index: -2;

    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));

    pointer-events: none;

    transition: opacity 0.3s linear;
    opacity: 0;
  }

  /* Prepare transition to open/close panel */
  transform: translateY(100%);
  transition: transform 0.35s cubic-bezier(0.77, 0, 0.175, 1);

  &.visible {
    transform: translateY(0%);

    &::before {
      opacity: 1;
    }
  }

  &.withoutZoomSlider {
    padding-right: 175px;
  }

  &.noRightPadding {
    padding-right: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const descriptionCls = css`
  /* Display alongside tools, i.e. with same padding */
  padding: 15px;

  color: #fff;
  font-size: 12px;
  font-family: var(
    --image-zoom--font-family,
    "franklin-gothic-urw",
    "droid-sans",
    helvetica,
    arial,
    sans-serif
  );
  hyphens: auto;

  & * {
    white-space: normal !important;
  }
`;

export const closeCls = css`
  appearance: none;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;

  position: absolute;
  top: -30px;
  left: 15px;

  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.5);

  box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0, 0.4);

  cursor: pointer;

  color: #fff;
  transition: color 0.1s linear;

  &:hover {
    color: #dc4320;
  }
`;

export const closeIconCls = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: 0;
  transition: opacity 0.3s linear;

  &.arrow {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .${closeCls}.arrow & {
    &.arrow {
      opacity: 1;
    }

    &.cross {
      opacity: 0;
    }
  }

  .${closeCls}.cross & {
    &.arrow {
      opacity: 0;
    }

    &.cross {
      opacity: 1;
    }
  }
`;
