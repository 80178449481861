// Detect device interfaces
export const isTouch = "ontouchend" in window;

export const isHandheld =
  // Default check
  "orientation" in window ||
  // The above can return a false negative on IE-based devices, so if
  // the browser is IE, also check if ondeviceorientation exists
  ((navigator.userAgent.toLowerCase().indexOf("msie") > -1 ||
    !!navigator.userAgent.match(/Trident.*rv\:11\./)) &&
    "ondeviceorientation" in window);

// Detect device OS
export const isIOS =
  navigator.platform.indexOf("iPhone") !== -1 ||
  navigator.platform.indexOf("iPad") !== -1 ||
  navigator.platform.indexOf("iPod") !== -1;

export const isWP =
  navigator.userAgent.toLowerCase().indexOf("windows phone") !== -1 && !isIOS;

export const isAndroid =
  // Determine if Android
  // User-agent check?
  (navigator.userAgent.toLowerCase().indexOf("android") > -1 ||
    // Fallback to feature-check...
    // Android is always a handheld touch-device
    (isTouch &&
      isHandheld &&
      // Eliminate other known handheld touch-devices
      navigator.userAgent.toLowerCase().indexOf("series60") === -1 &&
      navigator.userAgent.toLowerCase().indexOf("symbian") === -1 &&
      navigator.userAgent.toLowerCase().indexOf("windows ce") === -1 &&
      navigator.userAgent.toLowerCase().indexOf("blackberry") === -1)) &&
  // Eliminate false positives for iOS and Windows Phone
  !isIOS &&
  !isWP;
