import { css } from "linaria";

import { containerCls } from "./container";

export const toolsCls = css`
  padding: 15px;
  user-select: none;
  font-family: var(
    --image-zoom--font-family,
    "franklin-gothic-urw",
    "droid-sans",
    helvetica,
    arial,
    sans-serif
  );
  box-sizing: content-box;

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10001;

  display: none;
  pointer-events: none;

  &.visible {
    display: block;
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 706px;
    height: 276px;
    pointer-events: none;

    background: radial-gradient(
      ellipse farthest-side at bottom right,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    background: -webkit-radial-gradient(
      100% 100%,
      ellipse cover,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0) 75%
    );
  }
`;

export const buttonCls = css`
  pointer-events: auto;
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  background: transparent;
  -webkit-tap-highlight-color: rgba(220, 67, 32, 0.25);
  font-family: inherit;

  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 39px;
  height: 39px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 15px;
  color: #fff;
  vertical-align: middle;
  box-sizing: content-box;

  transition: color linear 125ms, border-color linear 125ms;

  .${containerCls}.mouse &:hover,
  .${containerCls}.touch &:active {
    color: #dc4320;
    border-color: #dc4320;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.small {
    top: 3px;
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }
`;

export const buttonIconCls = css`
  margin: 0 auto;
`;

export const zoomButtonCls = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  font-size: 23px;
  line-height: 39px;
  text-align: center;
`;

export const shrinkButtonCls = css`
  margin-right: 17px;
`;

export const enlargeButtonCls = css`
  margin-left: 17px;
`;

export const ratioButtonCls = css`
  position: relative;
  top: 7px !important;
  vertical-align: top;
`;

export const ratioButtonTextCls = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  font-size: 12px;
  text-align: center;
  line-height: 25px;

  user-select: none;
`;

export const groupCls = css`
  position: relative;
  display: inline-block;
  width: 96px;

  -webkit-tap-highlight-color: #dc4320;

  &.hidden {
    overflow: hidden;
    width: 0;
    height: 0;
    margin-right: -19px;
  }
`;

export const trackCls = css`
  pointer-events: auto;
  position: relative;
  top: 3.625px;
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 7px;
  z-index: 1;
  box-shadow: inset 0px 0px 0px 2px #fff;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.44);
    width: 1px;
    left: 19px;
    height: 8px;
    top: 3px;
    z-index: -1;
    box-shadow: 19px 0 0 rgba(255, 255, 255, 0.44),
      38px 0 0 rgba(255, 255, 255, 0.44), 57px 0 0 rgba(255, 255, 255, 0.44);
  }
`;

export const thumbCls = css`
  position: relative;
  height: 24px;
  width: 24px;
  margin-left: -12px;

  background-color: #dc4320;
  border-radius: 50%;
  box-shadow: -3px 0px 1.5px rgba(0, 0, 0, 0.3);

  top: -5px;
  left: 0px;
  transform: scale(1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: -webkit-grab;

  .${containerCls}.mouse &:hover,
  .${containerCls}.touch &:active {
    transform: scale(1.2);
  }
`;

export const thumbIconCls = css`
  position: absolute;
  top: -24px;
  bottom: -24px;
  left: calc(50% - 5px);

  width: 10px;
  background-color: #fff;

  /** We need to scale the thumb down in order to be able to work with the
   non-divisible measurements that the orignal thumb icon had (width = ~1.6px) */
  transform: scale(0.165);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;

    width: 10px;
    background-color: #fff;
  }

  &::before {
    left: -24px;
  }

  &::after {
    right: -24px;
  }
`;

export const thumbIndicatorCls = css`
  position: absolute;
  top: 27px;
  left: 0px;
  margin-left: -12.5px;

  color: #fff;
  font-size: 12px;

  user-select: none;
`;
