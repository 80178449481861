import React from "react";

import { SVG } from "./styles";
import { getDefaultHeight } from "./utils";

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

export const ArrowIcon: React.FC<Props> = ({
  color = "currentColor",
  ...svgProps
}) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      height={getDefaultHeight(svgProps, 32)}
      viewBox="0 0 52 32"
      {...svgProps}
      fill={color}
    >
      <path d="M0 6.719l26 25.281 6.875-6.719 19.094-18.563-6.906-6.719-19.094 18.594-19.094-18.594z"></path>
    </SVG>
  );
};
